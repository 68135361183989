<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <CCardHeader tag="div">
          <CIcon name="cil-grid"/>
          Thêm nhóm quyền
        </CCardHeader>
        <CCardBody style="min-height: 300px" tag="div">
          <CForm>
            <CRow>
              <CCol lg="6">
                <CInput label="Tên nhóm" horizontal :value.sync="item.name"/>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CSelect label="Loại đơn vị" horizontal :value.sync="item.loaiDonVi" :options="optionsLoaiDonVi"/>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <div role="group" class="form-group form-row">
                  <CCol tag="label" sm="3" class="col-form-label">Chức năng</CCol>
                  <CCol sm="9">
                    <multiselect v-model="selectedPermissions" :options="optionsPermissions" :multiple="true"
                                 group-values="items"
                                 group-label="groupName" :group-select="true" track-by="value"
                                 label="label" placeholder="Chọn chức năng" selectLabel="Nhấn để chọn"
                                 selectGroupLabel="Nhấn để chọn theo nhóm" selectedLabel="Đã chọn"
                                 deselectLabel="Nhấn để xóa" deselectGroupLabel="Nhấn để xóa theo nhóm"
                                 @input="updatePermissions">
                      <span slot="noResult">Không tìm thấy.</span>
                    </multiselect>
                  </CCol>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CTextarea label="Mô tả" horizontal :value.sync="item.description"/>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <div role="group" class="form-group form-row">
                  <CCol tag="label" sm="3" class="col-form-label">Trạng thái</CCol>
                  <CCol sm="9" class="form-inline">
                    <CInputCheckbox label="Hoạt động" :checked.sync="item.status"/>
                  </CCol>
                </div>
              </CCol>
            </CRow>
          </CForm>
          <CElementCover v-if="isLoading"/>
        </CCardBody>
        <CCardFooter tag="div" class="text-right">
          <CButton color="secondary" class="mr-2" @click="cancel">Hủy bỏ</CButton>
          <CButton color="primary" @click="saveItem" :disabled="isSaving">Lưu lại</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { CREATE_APPLICATION_ROLE } from '@/store/modules/application-role/actionTypes'
import { applicationRoleService } from '@/services/application-role.service'
import { enums } from '@/shared/enums'

export default {
  name: 'ApplicationRoleCreate',
  data () {
    return {
      item: {
        loaiDonVi: 1
      },
      selectedPermissions: [],
      isLoading: false,
      optionsPermissions: [],
      optionsLoaiDonVi: enums.loaiDonVi
    }
  },
  computed: {
    ...mapGetters('applicationRole', {
      isSaving: 'isSaving',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('applicationRole', {
      createItem: CREATE_APPLICATION_ROLE
    }),
    updatePermissions (val) {
      if (val && val.length > 0) {
        const arrPermissions = val.map(x => x.value)
        this.item.permissions = arrPermissions.join(',')
      }
    },
    async saveItem () {
      await this.createItem(this.item)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        await this.$router.push({ path: '/nhom-quyen' })
      }
    },
    cancel () {
      this.$router.push({ path: '/nhom-quyen' })
    }
  },
  watch: {
    'item.loaiDonVi': function (newVal, oldVal) {
      this.selectedPermissions = []
      applicationRoleService.getPermissions(newVal).then((data) => {
        this.optionsPermissions = data
      })
    }
  },
  async mounted () {
    this.isLoading = true
    this.optionsPermissions = await applicationRoleService.getPermissions(this.item.loaiDonVi)
    this.isLoading = false
  }
}
</script>
